/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

import '@popperjs/core'

import {Popover} from 'bootstrap'

import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/fontawesome'
import tippy from 'tippy.js';
import $ from 'jquery';
import 'tippy.js/dist/tippy.css';
import tinymce from 'tinymce'
import 'tinymce/plugins/table/plugin'
import 'tinymce/plugins/code/plugin'
import 'tinymce/plugins/paste/plugin'
import 'tinymce/themes/silver'
import 'tinymce/icons/default'

window.$ = $;


var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl)
})

$(document).ready(() => {
    $('[data-in_preview_disabled]').on('click', function (e) {
        e.preventDefault();
        window.location.href = '/register';
        return false;
    })

    let res = tinymce.init({
        selector: 'textarea.tinymce',
        theme: 'silver',
        plugins: 'table code paste',
        menubar: 'edit table tools',
        height : "480",
        paste_as_text: true,
        //valid_elements: "@[id|class|style|title],h1,h2,h3,h4,h5,h6,hr[size|noshade]`",
        toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | pastetext'

        //toolbar: 'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'
    })
})
tippy('[data-tippy-content]');


// start the Stimulus application
import './bootstrap';
