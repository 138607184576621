import {Controller} from 'stimulus';
import $ from 'jquery'

export default class extends Controller {
    static targets = ['form', 'preview_button', 'preview_loader', 'preview_iframe', 'pdf_text']

    connect() {

        $(this.form).on('change', () => {
            if(this.form.checkValidity()) {

                $(this.preview_button).trigger('click')
            }
        });

        $(this.preview_button).on('click', () => {
            $(this.preview_loader).addClass('loading');
        })

        $(this.preview_iframe).on('load', () => {
            $(this.preview_loader).removeClass('loading');
        })

        setTimeout( () => {

            $(this.preview_button).trigger('click')
        }, 1000)
        //const $form = $(this);
    }

    get form() {
        return this.formTarget;
    }
    get preview_button() {
        return this.preview_buttonTarget;
    }

    get preview_loader() {
        return this.preview_loaderTarget;
    }

    get preview_iframe() {
        return this.preview_iframeTarget;
    }
    get pdf_text() {
        return this.pdf_textTarget;
    }
}
