import {Controller} from 'stimulus';
import $ from 'jquery'
/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    static targets = ["label", "fileInput", "loader", "form"]

    defaultLabelText = '';

    connect() {

        var _this = this;
        this.defaultLabelText = $(this.labelTarget).text();

        var $form = $(_this.formTarget);
        console.log($(this.fileInputTarget).get(0).files);

        $(this.fileInputTarget).on('input', function (e) {

            if ($(this).get(0).files[0]) {

                if($(this).get(0).files.length > 1) {
                    alert('Nur 1 Datei');
                    return;
                }
                var file = $(this).get(0).files[0];
                console.log(file)
                $(_this.labelTarget).text(`Datei "${file.name}" ausgewählt`);
                $(_this.loaderTarget).css('width', '100%');


                $form.submit();
            } else {
                $(_this.labelTarget).text(_this.defaultLabelText);
            }
        });
        $(window).bind("pageshow", function() {
            var form = $(_this.formTarget);
            // let the browser natively reset defaults
            form[0].reset();
            $(_this.loaderTarget).css('width', '0');
            //console.log(_this.fileInputTarget)
            //_this.fileInputTarget.get(0).value = null
        });

        $form.on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
            e.preventDefault();
            e.stopPropagation();
        })
            .on('dragover dragenter', function() {
                $form.addClass('is-dragover');
            })
            .on('dragleave dragend drop', function() {
                $form.removeClass('is-dragover');
            })
            .on('drop', function(e) {
                _this.fileInputTarget.files = e.originalEvent.dataTransfer.files;
                $(_this.fileInputTarget).trigger('input')
            });
    }
}
