import {Controller} from 'stimulus';
import $ from 'jquery'

export default class extends Controller {
    static targets = ["links"]

    connect() {


        let $links =  $(this.linksTargets);
        $links.click(function() {
            $links.removeClass('current')
            $(this).addClass('current')

        })
    }
}
