import { Controller } from 'stimulus';
import $ from 'jquery'
import 'jquery-ui/ui/core'
import 'jquery-ui/ui/widgets/sortable'
import 'selectize'
import 'selectize/dist/css/selectize.bootstrap3.css'

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    static targets = [ "selects", "editor", "preview_modal", "preview_button", "generations_preview_iframe" ]
    connect() {

        /*
        let options = {
            //debug: 'info',
            placeholder: 'Inhalt',
            theme: 'snow',
        };
        let editor = new Quill(this.editorTarget, options)

        console.log(this.editor_inputTarget)
        editor.on('text-change', () => {

            this.editor_inputTarget.value = editor.root.innerHTML
        });

        if(this.editor_inputTarget.value) {
            const delta = editor.clipboard.convert(this.editor_inputTarget.value)

            editor.setContents(delta, 'silent')
        }

         */



        $(this.selectsTargets).selectize({
            placeholder: "Spalte auswählen",
            allowClear: true,
            plugins: ["drag_drop", "remove_button"],
            persist: false
        });



        $(this.selectsTargets).change(() => {
            $(this.element).trigger('submit')
            $('body').addClass('loading')
        })

    }

    clearPreviewIframe() {
        let frame = this.generations_preview_iframeTarget
        let frameDoc = frame.contentDocument || frame.contentWindow.document;
        frameDoc.removeChild(frameDoc.documentElement);
    }

}
