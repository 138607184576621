import {Controller} from 'stimulus';
import $ from 'jquery'
import {Popover} from 'bootstrap'

export default class extends Controller {

    connect() {
        let confirmPopover =  new Popover(this.element, {
            container: 'body'
        })

        $(this.element).on('click', () => {
            navigator.clipboard.writeText(this.element.dataset.clipboardText)
            confirmPopover.show()
            setTimeout(() => {
                confirmPopover.hide()
            }, 2000)

        })
    }
}
