import {Controller} from 'stimulus';
import $ from 'jquery'
import {Popover} from "bootstrap";

export default class extends Controller {
    static targets = ["input", "output", "copyContainer", "content"]

    connect() {

        let confirmPopover = new Popover(this.outputTarget, {
            container: 'body'
        })

        this.$input.on('change keyup', () => {
            let word = this.$input.val()

            if(word) {

                word = `[[${this.sanitizeTitle(word)}]]`
            } else {
                word = ''
            }
            this.$output.text(word)
            this.$copyContainer.attr('data-clipboard-text', word)
        })


    }

    get $input() {
        return $(this.inputTarget)
    }

    get $output() {
        return $(this.outputTarget)
    }

    get $content() {
        return $(this.contentTarget)
    }

    get $copyContainer() {
        return $(this.copyContainerTarget)
    }

    sanitizeTitle(str) {
        return str
        //return str.replace(/[^A-Z0-9]/ig, "_").toLowerCase()
    }

    toggleContent() {
        console.log('toggled')
        this.$content.slideToggle()
    }

}
