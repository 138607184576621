import {Controller} from 'stimulus';
import $ from 'jquery'

export default class extends Controller {
    static targets = ["content", "label"]

    connect() {

        const $labelTarget = $(this.labelTarget);
        const $contentTarget = $(this.contentTarget);


        let handleChange = () => {

            if($labelTarget.find('input[type="checkbox"]').is(':checked')) {
                $labelTarget.removeClass('collapsed')
                $contentTarget.slideDown()
            } else {
                $labelTarget.addClass('collapsed')
                $contentTarget.slideUp()
            }
        }



        handleChange();
        setInterval(handleChange, 200)
        $labelTarget.on('change', handleChange);

    }
}
