import {Controller} from 'stimulus';
import $ from 'jquery'
import {Popover} from 'bootstrap'

export default class extends Controller {

    static targets = ['count', 'notificationCenter']
    connect() {
        this.refreshCount()


        $(document).click((e)=> {
            var target = e.target;

            if (!$(target).is(this.element) && !$(target).parents().is(this.element)) {

                $(this.notificationCenterTarget).removeClass('show')
            }
        });
    }

    refreshCount() {
        let count = $('.notification-list .notification-block').length;

        $(this.countTarget).text(count)

        if(count === 0) {
            $(this.countTarget).fadeOut();
        } else {
            $(this.countTarget).fadeIn();

        }
    }
    show(event) {
        $(this.notificationCenterTarget).toggleClass('show')
    }

    trash(event, element) {

        $(this.notificationCenterTarget).addClass('loading')
        $.ajax({
            method: 'DELETE',
            url: `/notification/${event.currentTarget.dataset.id}`,
            success: (res) => {
                $('.notification-list').replaceWith(res)
                this.refreshCount()

            },
            complete: () => {

                $(this.notificationCenterTarget).removeClass('loading')
            }
        })

    }
}
